

























































import GoBackHeader from "@/components/shared/GoBackHeader.vue";

import { SET_COMPANY } from "@/store/modules/auth/constants";

import {
  GET_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: { GoBackHeader },
  name: "EditEmailTemplate",
  data() {
    return {
      subject: "",
      body: "",
      templateId: 0 as number,
      email_editor_options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"]
          ]
        },
        placeholder: "Type answer here",
        table: [] // Answer editor options
      }
    };
  },
  created() {
    // Get the ID from the route
    this.templateId = Number(this.$route.params.id);
  },
  async mounted() {
    await this.fetch_email_template(this.templateId); // Fetch template on component mount
  },
  methods: {
    ...mapActions("recruiter", {
      get_email_template: GET_EMAIL_TEMPLATE,
      update_email_template: UPDATE_EMAIL_TEMPLATE
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    ...mapMutations("auth", {
      set_company: SET_COMPANY
    }),
    // Function to update company assessment setting
    async saveChanges() {
      // API Call
      const payload = {
        subject: this.subject,
        body: this.body,
        template_id: this.templateId
      };

      const response = await this.update_email_template(payload);

      if (response) {
        this.set_company(response);
        this.set_root_notification(
          this.$t("recruiter.settings.email-templete.update")
        );
        await this.$router.push("/settings/email-templates");
      }
    },
    async fetch_email_template(id: number) {
      let response = await this.get_email_template(id);
      response = response.data;
      if (response) {
        this.subject = response.subject;
        this.body = response.body;
      }
    },
    event_cancellation(event: any) {
      event.preventDefault();
    }
  }
});
